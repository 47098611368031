import { Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Stack } from '@mui/system';

interface LoadingProps {
  open: boolean,
  wording?: string
  children?: JSX.Element[] | JSX.Element;
}

export default function Loading(props: LoadingProps) {
  const { open, wording } = props;

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: '9999' }}
      open={open}
    >
      <Stack direction="column" justifyContent="center" alignItems="center">
        <CircularProgress color="primary" />
        {props.children ? <Typography variant="subtitle1" sx={{ my: 2, color: 'white' }}>{props.children}</Typography> : <Typography variant="subtitle1" sx={{ my: 2, color: 'white' }}>{wording}</Typography>}
      </Stack>
    </Backdrop>
  );
}
