import service from './index'

const prefix = '/common-uam';

// ACL
export const getAclList = (pageSize: number = 1000000, pageNum: number = 1) => service.get(`${prefix}/acls/list?pageSize=${pageSize}&pageNum=${pageNum}`);

export const addACL = (aclInfo: {}) => service.post(prefix + '/acls', aclInfo);

export const updateACL = (aclInfo: {}) => service.put(prefix + '/acls', aclInfo);

export const updateACLStatus = (body: {}) => service.put(prefix + '/acls/status/active', body);

export const deleteAcl = (params: Object) => service.delete(prefix + `/acls/ids`, { data: params });

export const getAclMousules = () => service.get(prefix + `/resources/modules`);

export const getAclMousuleResource = (module: string, pageSize: number = 1000000, pageNum: number = 1) => service.get(prefix + `/resources/list?module=${module}&pageSize=${pageSize}&pageNum=${pageNum}`);

export const getAclModuleByAclId = (aclId: String) => service.get(prefix + `/acls/resources/${aclId}`);

export const exportAclList = (type: Number) => service.get(`${prefix}/acls/export/list?exportType=${type}`)

export const importAcl = (file: any) => service.post(`${prefix}/acls/import`)

// User
export const getUserList = (keyword: string, showUserType: string, pageSize: number, pageNum: number) => service.get(`${prefix}/users/list?keyword=${keyword}&showUserType=${showUserType}&pageSize=${pageSize}&pageNum=${pageNum}`);

export const addUser = (userInfo: {}) => service.post(prefix + '/users', userInfo);

export const getOrganizationList = () => service.get(prefix + '/organization/list');

export const updateUser = (body: {}) => service.put(prefix + '/users/basic-info', body);

export const updateUserStatus = (body: {}) => service.put(prefix + '/users/user-status', body);

export const getUserById = (userId: string) => service.get(`${prefix}/users/${userId}`);

export const exportUserList = (type: Number) => service.get(`${prefix}/users/export/list?exportType=${type}`)

export const getSameAclUser = (module: string, userId: string) => service.get(`${prefix}/users/reassign/same-acl-user?module=${module}&userId=${userId}`);

export const importUser = (body: any) => service.post(`${prefix}/users/import`, body);

export const fetchGetActiveUserList = () => service.get(`${prefix}/users/active-users`);

export const getDomainList = () => service.get(`${prefix}/domains/list`);

// add encoder for reason
export const deleteUser = (id: string, reason: string) => service.delete(`${prefix}/users?userId=${id}&reason=${reason}`);

export const getUserBindGroupsByUserId = (userId: string) => service.get(`${prefix}/users/associated-groups/${userId}`);

export const getUserBindRolesByUserId = (userId: string) => service.get(`${prefix}/users/associated-roles/${userId}`);

export const putUserBindGroups = (body: {}) => service.put(`${prefix}/users/associated-groups`, body);

export const putUserBindRoles = (body: {}) => service.put(`${prefix}/users/associated-roles`, body);

export const putUserLockStatus = (userId: string, lockValue: Number) => service.put(`${prefix}/users/lock-status/${userId}/${lockValue}`);

export const bindUserWithSingleGroup = (userId: string, groupId: string) => service.put(`${prefix}/users/group?userId=${userId}&groupId=${groupId}`);

export const bindUserWithSingleRole = (userId: string, roleId: string) => service.put(`${prefix}/users/role?userId=${userId}&roleId=${roleId}`);

export const unbindUserWithSingleRole = (userId: string, roleId: string) => service.delete(`${prefix}/users/role?userId=${userId}&roleId=${roleId}`);

export const unbindUserWithSingleGroup = (userId: string, groupId: string) => service.delete(`${prefix}/users/group?userId=${userId}&groupId=${groupId}`);

export const putUserBasicInfo = () => service.put(`${prefix}/users/basic-info`);

// Role
export const getRoleList = (pageSize: number = 1000000, pageNum: number = 1) => service.get(`${prefix}/roles/list?pageSize=${pageSize}&pageNum=${pageNum}`);

export const addRole = (roleInfo: {}) => service.post(prefix + '/roles', roleInfo);

export const updateRole = (roleInfo: {}) => service.put(prefix + '/roles', roleInfo);

export const relativeUser = (roleId: string) => service.get(`${prefix}/roles/relative/user?roleId=${roleId}`);

export const updateRoleStatus = (body: {}) => service.put(prefix + '/roles/status/active', body);

export const deleteRole = (params: Object) => service.delete(prefix + `/roles/ids`, { data: params });

export const bindACL = (requestBody: {}) => service.put(prefix + '/roles/acl/binding', requestBody)

export const getBindACL = (roleId: string) => service.get(`${prefix}/roles/acl/${roleId}`);

export const exportRoleList = (type: Number) => service.get(`${prefix}/roles/export/list?exportType=${type}`)

export const getRoleById = (roleId: string | Number) => service.get(prefix + `/roles/${roleId}`);

export const importRole = (file: any) => service.post(`${prefix}/roles/import`)

// add encoder for reason
export const getRoleBindUserByRoleId = (roleId: Number) => service.get(`${prefix}/roles/user/${roleId}`);

export const getRoleBindGroupsByRoleId = (roleId: Number) => service.get(`${prefix}/roles/group/${roleId}`);

export const getRoleBindAclByRoleId = (roleId: Number) => service.get(`${prefix}/roles/acl/${roleId}`);

export const putRoleBindGroups = (roleId: any, groupId: any) => service.put(`${prefix}/roles/group?roleId=${roleId}&groupId=${groupId}`);

export const putRoleBindUsers = (roleId: any, userId: any) => service.put(`${prefix}/roles/user?roleId=${roleId}&userId=${userId}`);

export const putRoleBindAcls = (roleId: any, aclId: any) => service.put(`${prefix}/roles/acl?roleId=${roleId}&aclId=${aclId}`);

export const deleteRoleBindGroups = (roleId: any, groupId: any) => service.delete(`${prefix}/roles/group?roleId=${roleId}&groupId=${groupId}`);

export const deleteRoleBindUsers = (roleId: any, userId: any) => service.delete(`${prefix}/roles/user?roleId=${roleId}&userId=${userId}`);

export const deleteRoleBindAcls = (roleId: any, aclId: any) => service.delete(`${prefix}/roles/acl?roleId=${roleId}&aclId=${aclId}`);

// Group
export const getGroupList = () => service.get(prefix + '/groups/list');

export const getGroupById = (groupId: string) => service.get(`${prefix}/groups/${groupId}`);

export const getBasicGroupList = () => service.get(prefix + '/groups/list/basic-info');

export const addGroup = (body: {}) => service.post(prefix + '/groups', body);

export const updateGroup = (body: {}) => service.put(prefix + '/groups/basic-info', body);

export const updateGroupStatus = (body: {}) => service.put(prefix + '/groups/group-status', body);

export const deleteGroup = (id: string, reason: string) => service.delete(`${prefix}/groups?groupId=${id}&reason=${reason}`);

export const putGroupBindUsers = (body: {}) => service.put(`${prefix}/groups/associated-users`, body);

export const getGroupBindUsersByGroupId = (groupId: string) => service.get(`${prefix}/groups/associated-users/${groupId}`);

export const getGroupBindRolesByGroupId = (groupId: string) => service.get(`${prefix}/groups/associated-roles/${groupId}`);

export const putGroupBindRoles = (body: {}) => service.put(`${prefix}/groups/associated-roles`, body);

export const bindGroupWithSingleUser = (userId: string, groupId: string) => service.put(`${prefix}/groups/user?userId=${userId}&groupId=${groupId}`);

export const bindGroupWithSingleRole = (groupId: string, roleId: string) => service.put(`${prefix}/groups/role?groupId=${groupId}&roleId=${roleId}`);

export const unbindGroupWithSingleRole = (groupId: string, roleId: string) => service.delete(`${prefix}/groups/role?groupId=${groupId}&roleId=${roleId}`);

export const unbindGroupWithSingleUser = (userId: string, groupId: string) => service.delete(`${prefix}/groups/user?userId=${userId}&groupId=${groupId}`);

export const exportGroupList = (type: Number) => service.get(`${prefix}/groups/export/list?exportType=${type}`)

export const importGroup = (file: any) => service.post(`${prefix}/groups/import`)

// Resource
export const getResourceList = () => service.get(prefix + '/resources/list');

export const addResource = () => service.post(prefix + '/resources');

export const updateResource = () => service.put(prefix + '/resources');

export const deleteResource = () => service.delete(prefix + '/resources');

export const getSpecifyResourceList = (body: any) => service.post(prefix + '/resources/specified/list', body);

export const getRelatedMenuResourceList = (body: any) => service.post(prefix + '/resources/menu/related-resource', body);

export const getQuickSiteResourceList = () => service.get(prefix + '/resources/quick-sight/list');

export const getSpecifyRelatedResourceList = (body: any) => service.post(prefix + '/resources/specified/related-list', body);

// audit
export const getUAMAuditInfo = (params = {}) => service.post(prefix + '/log/audit-info', params);

// Test403
export const testResource2 = () => service.get(prefix + '/content/record');
export const testResource3 = () => service.get(prefix + '/content/extracted');

// DeleteRecords
export const getDeleteRecordByType = (type: number) => service.get(`${prefix}/log/list/delete-record?deleteRecordType=${type}`);

export const getStatusChangeLogByType = (recordId: string, type: number) => service.get(`${prefix}/log/list/status-change-log?recordId=${recordId}&recordType=${type}`);

export const getImportTemplate = (params = {}) => service.post(`${prefix}/import/template`, params);

export const EXPORT_ACL_URL = `${prefix}/acls/export/list`;

export const EXPORT_GROUPS_URL = `${prefix}/groups/export/list`;

export const EXPORT_USERS_URL = `${prefix}/users/export/list`;

export const EXPORT_ROLES_URL = `${prefix}/roles/export/list`;

export const IMPORT_ACL_URL = `${prefix}/acls/import`;

export const IMPORT_GROUPS_URL = `${prefix}/groups/import`;

export const IMPORT_USERS_URL = `${prefix}/users/import`;

export const IMPORT_ROLES_URL = `${prefix}/roles/import`;

export const DOWNLOAD_TEMPLATE_URL = `${prefix}/import/template`;

export const UAM_PREFIX = prefix;

//e-signauture password
export const judgeUserHasSignaturePassword = () => service.get(`${prefix}/users/e-signature/exist`);

export const createElectronicSignaturePassword = (params: {}) => service.post(`${prefix}/users/e-signature`, params);

export const updateElectronicSignaturePassword = (params: {}) => service.put(`${prefix}/users/e-signature`, params);

export const getUserListWorkflowRole = (roleName: string) => service.get(`${prefix}/roles/user/list?roleName=${roleName}`);

// System Settings
export const getSystemSettings = () => service.get(`${prefix}/system-settings/`);

export const udpateSystemSettings = (body: any) => service.put(`${prefix}/system-settings/`, body);

export const resetSystemSettings = () => service.put(`${prefix}/system-settings/resetting`);
