import service from './index'

const prefix = '/syslib';

export const getSysDeleteRecordByType = (type: number) => service.get(`${prefix}/log/list/delete-record?deleteRecordType=${type}`);

// Country
export const createLibCountry = (countryInfo: {}) => service.post(prefix + '/lib/country', countryInfo);

export const updateLibCountry = (countryInfo: {}) => service.put(prefix + '/lib/country', countryInfo);

export const deleteLibCountry = (params: any) => service.delete(prefix + '/lib/country', { data: params });

export const fetchAllActiveCountry = () => service.get(prefix + '/lib/country/list');

export const fetchLibCountry = (pageSize: number = 1000000, pageNum: number = 1) => service.get(`${prefix}/lib/country/page?pageSize=${pageSize}&pageNum=${pageNum}`);

export const fetchAllActiveCountryDistinct = () => service.get(`${prefix}/lib/country/distinct/list`);

// Company Unit => Business Unit
export const createLibCompanyUnit = (countryInfo: {}) => service.post(prefix + '/lib/company-unit', countryInfo);

export const updateLibCompanyUnit = (countryInfo: {}) => service.put(prefix + '/lib/company-unit', countryInfo);

export const fetchAllActiveCompanyUnit = () => service.get(prefix + '/lib/company-unit/list');

export const getCompanyUnitById = (id = '') => service.get(prefix + `/lib/company-unit/${id}`);

export const deleteLibCompanyUnit = (params: any) => service.delete(prefix + '/lib/company-unit', { data: params });

export const fetchLibCompanyUnit = (pageSize: number = 1000000, pageNum: number = 1) => service.get(`${prefix}/lib/company-unit/page?pageSize=${pageSize}&pageNum=${pageNum}`);

// Country Group
export const createLibCountryGroup = (countryInfo: {}) => service.post(prefix + '/lib/country-group', countryInfo);

export const updateLibCountryGroup = (countryInfo: {}) => service.put(prefix + '/lib/country-group', countryInfo);

export const deleteLibCountryGroup = (params: any) => service.delete(prefix + '/lib/country-group', { data: params });

export const fetchLibCountryGroup = (pageSize: number = 1000000, pageNum: number = 1) => service.get(`${prefix}/lib/country-group/page?pageSize=${pageSize}&pageNum=${pageNum}`);

export const fetchAllActiveCountryGroup = () => service.get(prefix + '/lib/country-group/list');
// Function
export const createLibFunction = (countryInfo: {}) => service.post(prefix + '/lib/function', countryInfo);

export const updateLibFunction = (countryInfo: {}) => service.put(prefix + '/lib/function', countryInfo);

export const fetchAllActiveFunction = () => service.get(prefix + '/lib/function/list');

export const deleteLibFunction = (params: any) => service.delete(prefix + '/lib/function', { data: params });

export const fetchLibFunction = (pageSize: number = 1000000, pageNum: number = 1) => service.get(`${prefix}/lib/function/page?pageSize=${pageSize}&pageNum=${pageNum}`);

// Organization
export const createLibOrganization = (countryInfo: {}) => service.post(prefix + '/lib/organization', countryInfo);

export const updateLibOrganization = (countryInfo: {}) => service.put(prefix + '/lib/organization', countryInfo);

export const fetchAllActiveOrganization = () => service.get(prefix + '/lib/organization/list');

export const getOrganizationById = (id = '') => service.get(prefix + `/lib/organization/${id}`);

export const deleteLibOrganization = (params: any) => service.delete(prefix + '/lib/organization', { data: params });

export const fetchLibOrganization = (pageSize: number = 1000000, pageNum: number = 1) => service.get(`${prefix}/lib/organization/page?pageSize=${pageSize}&pageNum=${pageNum}`);

// Tenant
export const createLibTenant = (countryInfo: {}) => service.post(prefix + '/lib/tenant', countryInfo);

export const updateLibTenant = (countryInfo: {}) => service.put(prefix + '/lib/tenant', countryInfo);

export const fetchAllActiveTenant = () => service.get(prefix + '/lib/tenant/list');

export const deleteLibTenant = (params: any) => service.delete(prefix + '/lib/tenant', { data: params });

export const fetchLibTenant = (pageSize: number = 1000000, pageNum: number = 1) => service.get(`${prefix}/lib/tenant/page?pageSize=${pageSize}&pageNum=${pageNum}`);

//10-Category,11-Dosage Form,12-Generic Name,13-Health Authority,14-Product Type,15-Route of Administration,16-Sub Category,17-Submission Type,18-Topic,19-Trade Name
export const createLibType = (countryInfo: {}) => service.post(prefix + '/lib/', countryInfo);

export const updateLibType = (countryInfo: {}) => service.put(prefix + '/lib/', countryInfo);

export const fetchAllActiveType = (type: number) => service.get(prefix + `/lib/${type}/list`);

export const deleteLibType = (params: any) => service.delete(prefix + '/lib/', { data: params });

export const fetchLibType = (type: number, pageSize: number = 1000000, pageNum: number = 1) => service.get(`${prefix}/lib/${type}/page?pageSize=${pageSize}&pageNum=${pageNum}`);

export const getCategoryTreeList = (keywords: string) => service.get(`${prefix}/system/category-tree?keyword=${keywords}`);

// CountryGroupMapping
export const createLibInternalMapping = (countryInfo: {}) => service.post(prefix + '/lib/internal', countryInfo);

export const updateLibInternalMapping = (countryInfo: {}) => service.put(prefix + '/lib/internal', countryInfo);

export const fetchLibInternalCountryGroupMapping = (type: number) => service.get(prefix + `/lib/internal/group/${type}`);

export const fetchLibInternalCountryMapping = (type: number) => service.get(prefix + `/lib/internal/member/${type}`);

export const deleteLibInternalMapping = (params: any) => service.delete(prefix + '/lib/internal', { data: params });

export const fetchLibInternalMapping = (type: number, pageSize: number = 1000000, pageNum: number = 1) => service.get(`${prefix}/lib/internal/page/${type}?pageSize=${pageSize}&pageNum=${pageNum}`);

export const fetchSingleLibInternalMapping = (id: string) => service.get(prefix + `/lib/internal/${id}`);

// Submissions
export const getSubmissionTree = (keywords: string = ``) => service.get(`${prefix}/system/submission-type-tree?keyword=` + encodeURIComponent(keywords))

// audit
export const getSyslibAuditInfo = (params = {}) => service.post(prefix + '/log/audit-info', params);

export const EXPORT_COMPANY_UNIT_URL = `${prefix}/lib/company-unit/export`;

export const EXPORT_COUNTRY_URL = `${prefix}/lib/country/export`;

export const EXPORT_COUNTRY_GROUP_URL = `${prefix}/lib/country-group/export`;

export const EXPORT_COUNTRY_MAPPING_URL = `${prefix}/lib/country-mapping/export`;

export const EXPORT_FUNCTION_URL = `${prefix}/lib/function/export`;

export const EXPORT_INTERNAL_URL = `${prefix}/lib/internal/export`;

export const EXPORT_COMPANY_URL = `${prefix}/lib/company/export`;

export const EXPORT_ORGANIZATION_URL = `${prefix}/lib/organization/export`;

export const EXPORT_TENANT_URL = `${prefix}/lib/tenant/export`;

export const SYSLIB_PREFIX = prefix;