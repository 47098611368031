import {
  Box, Button, FormControl, Popover, Drawer, FormLabel, FormHelperText, InputLabel, OutlinedInput, TextField, Typography, Grid, Link,
  InputAdornment, IconButton
} from "@mui/material";
import { Close, HelpOutline, Check, Clear } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { getPasswordComplexityPolicy, logout, updatePassword } from '../api/login';
import { useSnackbar } from "notistack";
import { firstWordCase } from "../common/utils";
import Loading from "../components/Loading";

const passwordValidator = require('password-validator');

export interface IProps {
  children: JSX.Element[] | JSX.Element;
}

interface IDeleteDialogProps extends IProps {
  visible: boolean;
  cancel: () => void;
  disabledSubmit: boolean;
  submit: () => void;
  username: string;
  open: (value: boolean) => void;
}

const ChangePswDialog = (props: IDeleteDialogProps): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const initValues = {
    currentPassword: '',
    currentPasswordError: '',
    newPassword: '',
    newPasswordError: '',
    confirmPassword: '',
    confirmPasswordError: ''
  }
  const systemSettingInit = {
    passwordHistoryLimit: 5,
    passwordMinimumLength: 8,
    passwordRequireSymbols: true,
    passwordRequireNumbers: true,
    passwordRequireUppercase: true,
    passwordRequireLowercase: true
  }
  const [systemSetting, setSystemSetting] = useState(systemSettingInit);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(initValues);
  const [openPopover, setOpenPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const showCriteria = (event: any) => {
    setOpenPopover(true)
    setAnchorEl(event.currentTarget)
  }

  const hideCriteria = (event: any) => {
    setOpenPopover(false)
    setAnchorEl(null)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }

  const formatPasswordValidateError = (errors: Array<string>) => {
    for (let i = 0; i < errors.length; i++) {
      return 'Please check the password policy by hovering on ';
      // if (errors[i] === 'min') {
      //   return 'Please check the password policy by hovering on "Symbol"';
      // } else if (errors[i] === 'lowercase') {
      //   return 'Please check the password policy by hovering on "Symbol"';
      // } else if (errors[i] === 'uppercase') {
      //   return 'Please check the password policy by hovering on "Symbol"';
      // } else if (errors[i] === 'digits') {
      //   return 'Please check the password policy by hovering on "Symbol"';
      // } else if (errors[i] === 'symbols') {
      //   return 'Please check the password policy by hovering on "Symbol"';
      // }
    }
    return '';
  };

  const setNewPassword = (value: string) => {
    // create a password schema
    let schema = new passwordValidator();
    schema = schema.is().min(systemSetting.passwordMinimumLength);
    if (systemSetting.passwordRequireLowercase) {
      schema = schema.has().lowercase();
    }
    if (systemSetting.passwordRequireUppercase) {
      schema = schema.has().uppercase();
    }
    if (systemSetting.passwordRequireNumbers) {
      schema = schema.has().digits();
    }
    if (systemSetting.passwordRequireSymbols) {
      schema = schema.has().symbols();
    }
    const validationRulesErrors = schema.validate(value, { list: true });
    let errorMsg = '';
    let confirmPasswordError: any = values.confirmPasswordError

    if (validationRulesErrors.length > 0) {
      errorMsg = formatPasswordValidateError(validationRulesErrors);
    } else {
      if (!!values.confirmPassword && value !== values.confirmPassword) {
        errorMsg = 'Password entries did not match'
      } else {
        confirmPasswordError = null
      }
      if (value === values.currentPassword) {
        errorMsg = 'Password cannot be the same as the current password'
      }
    }
    setValues({
      ...values,
      newPassword: value,
      newPasswordError: errorMsg,
      confirmPasswordError: confirmPasswordError
    })
  }

  const setNewPasswordComfirm = (value: string) => {
    let passwordNotSameError = '';
    let newPasswordError: any = values.newPasswordError
    if (value !== values.newPassword) {
      passwordNotSameError = 'Password entries did not match'
    } else {
      newPasswordError = null
    }

    setValues({
      ...values,
      confirmPassword: value,
      confirmPasswordError: passwordNotSameError,
      newPasswordError: newPasswordError
    })
  }

  const setCurrentPassword = (value: string) => {
    setValues({
      ...values,
      currentPassword: value
    })
  }

  const cancel = () => {
    setValues({
      currentPassword: '',
      currentPasswordError: '',
      newPassword: '',
      newPasswordError: '',
      confirmPassword: '',
      confirmPasswordError: ''
    })
    props.cancel()
  }

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (Boolean(values.newPasswordError) || Boolean(values.confirmPasswordError)) return
    setLoading(true)
    updatePassword({
      username: props.username,
      oldPassword: values.currentPassword,
      newPassword: values.newPassword
    }).then((data: any) => {
      if (data.code === 200) {
        enqueueSnackbar('Changed Password Successfully', { variant: "success", anchorOrigin: { vertical: 'top', horizontal: 'center' } });
        setValues({
          currentPassword: '',
          currentPasswordError: '',
          newPassword: '',
          newPasswordError: '',
          confirmPassword: '',
          confirmPasswordError: ''
        })
        props.submit()
      }
    }).catch((err: any) => {
      if (err.response.data.code === 1066) {
        enqueueSnackbar(`Current password cannot be from the last ${systemSetting.passwordHistoryLimit} used passwords`, { variant: "error", anchorOrigin: { vertical: 'top', horizontal: 'center' } });
      } else if (err.response.data.code === 1067) {
        enqueueSnackbar('You have exceeded the limit to change your password. Please contact the system administrator.', { variant: "error", anchorOrigin: { vertical: 'top', horizontal: 'center' } });
      } else {
        enqueueSnackbar(firstWordCase(err.response.data.message), { variant: "error", anchorOrigin: { vertical: 'top', horizontal: 'center' } });
      }
    }).finally(() => {
      setLoading(false)
    })

  }

  useEffect(() => {
    setLoading(true);
    getPasswordComplexityPolicy().then((data: any) => {
      setSystemSetting({ ...data.data })
    }).catch((err: any) => {

    }).finally(() => {
      setLoading(false);
    }
    )
  }, [])

  return (
    <Drawer anchor={'right'} className="drawer-popup-right" onClose={cancel} open={props.visible}>
      <Box sx={{ width: 400, display: 'flex', flexDirection: 'column' }} component="form" onSubmit={submit}>
        <div className="drawer-popup-header">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            Change Password
          </Typography>
          <Button onClick={() => { cancel() }}>
            <Close />
          </Button>
        </div>
        <div className="drawer-popup-body form-group" style={{ paddingRight: 0 }}>
          <Box sx={{ width: '100%', marginTop: '0' }}>
            <Grid container>
              <FormControl
                error={!!values.currentPasswordError}
                required
                fullWidth
                sx={{ mb: 2 }}
                variant="outlined"
                size="small">
                <InputLabel htmlFor="currentPassword">Current Password</InputLabel>
                <OutlinedInput
                  id="currentPassword"
                  type='password'
                  value={values.currentPassword}
                  onInvalid={(e: any) => { e.target.setCustomValidity('Please enter the current password') }}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        edge="end">
                        {values.currentPassword.length === 0 ? '' : (!values.currentPasswordError ? <Check color="success" /> : <Clear color="error" />)}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Re-enter Password"
                />
                <FormHelperText id="component-error-text">{values.currentPasswordError}</FormHelperText>
              </FormControl>
              <FormControl
                error={!!values.newPasswordError}
                required
                fullWidth
                sx={{ mb: 2 }}
                variant="outlined"
                size="small">
                <InputLabel htmlFor="password">New Password</InputLabel>
                <OutlinedInput
                  id="password"
                  type={'password'}
                  value={values.newPassword}
                  onInvalid={(e: any) => { e.target.setCustomValidity('Please enter the password') }}
                  onChange={(e) => setNewPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={(e) => showCriteria(e)}
                        onMouseDown={(e) => handleMouseDownPassword(e)}
                      >
                        <HelpOutline />
                      </IconButton>
                      <IconButton
                        edge="end"
                      >
                        {values.newPassword.length === 0 ? '' : (!values.newPasswordError ? <Check color="success" /> : <Clear color="error" />)}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="New Password"
                />
                <FormHelperText style={{ display: 'flex', alignItems: 'center' }}>{values.newPasswordError}{!!values.newPasswordError && <HelpOutline style={{ color: 'rgba(0, 0, 0, 0.54)' }} />}</FormHelperText>
                <Popover className='pwd-popover'
                  open={Boolean(anchorEl)}
                  onClose={hideCriteria}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  disableRestoreFocus
                >
                  <Typography sx={{ p: 1 }}>
                    New Password cannot be same as previously used password
                  </Typography>
                  <Typography sx={{ p: 1 }}>Your password should contain:
                    <ul>
                      <li>Minimum length of {systemSetting.passwordMinimumLength} characters</li>
                      {systemSetting.passwordRequireNumbers && <li>At least 1 Numerical character (0-9)</li>}
                      {systemSetting.passwordRequireSymbols && <li>At least 1 Special character</li>}
                      {systemSetting.passwordRequireUppercase && <li>At least 1 Uppercase letter</li>}
                      {systemSetting.passwordRequireLowercase && <li>At least 1 Lowercase letter</li>}
                    </ul>
                  </Typography>
                </Popover>
              </FormControl>
              <FormControl
                error={!!values.confirmPasswordError}
                required
                fullWidth
                sx={{ mb: 2 }}
                variant="outlined"
                size="small">
                <InputLabel htmlFor="passwordConfirm">Confirm Password</InputLabel>
                <OutlinedInput
                  id="passwordConfirm"
                  type='password'
                  value={values.confirmPassword}
                  onInvalid={(e: any) => { e.target.setCustomValidity('Please enter the password') }}
                  onChange={(e) => setNewPasswordComfirm(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        edge="end">
                        {values.confirmPassword.length === 0 ? '' : (!values.confirmPasswordError ? <Check color="success" /> : <Clear color="error" />)}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Re-enter Password"
                />
                <FormHelperText id="component-error-text">{values.confirmPasswordError}</FormHelperText>
              </FormControl>
              <Link href="/forgot-password" variant="body2" className="forget-link">
                Forgot Password?
              </Link>
            </Grid>
          </Box>
        </div>
        <div className="drawer-popup-footer">
          <Grid>
            <Button variant="outlined" color="secondary" size="small" onClick={() => { cancel() }}>Cancel</Button>
            <Button variant="contained" size="small" type="submit" disabled={loading}>Submit</Button>
          </Grid>
        </div>
      </Box>
      <Loading open={loading} />
    </Drawer>
  )
}

export default ChangePswDialog