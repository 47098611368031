import { IconButton, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menus } from "./menuList";
import { RIMS_LOCAL_STG_CONSTANTS } from "../constants";
import CommonDialog from "./CommonDialog";

interface BreadCrumbProps {
  openMenuList: Menus[];
}

export default function BreadCrumb(props: BreadCrumbProps) {
  const { openMenuList } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const breadCrumbs = openMenuList;
  const currentBreadCrumb = breadCrumbs[breadCrumbs.length - 1];
  const backBreadCrumb = breadCrumbs[breadCrumbs.length - 2];

  // const [isBackClick, setIsBackClick] = useState(false);
  // const [currentPath, setCurrentPath] = useState('');
  // const [currentState, setCurrentState] = useState({});
  // const [openDialog, setOpenDialog] = useState(false);

  const handleClickBackBtn = () => {
    navigate(backBreadCrumb.path, { state: backBreadCrumb.breadCrumbState });
    // setIsBackClick(true);
    // let canJump = currentBreadCrumb?.breadCrumbTitle === 'Edit User' || currentBreadCrumb?.breadCrumbTitle === 'New User'
    // if (localStorage.getItem(RIMS_LOCAL_STG_CONSTANTS.DISABLE_BACK_PREVIOUS) == 'true' && canJump) {
    //   setOpenDialog(true);
    // } else {
    //   localStorage.setItem(RIMS_LOCAL_STG_CONSTANTS.DISABLE_BACK_PREVIOUS, 'false');
      navigate(backBreadCrumb.path, { state: backBreadCrumb.breadCrumbState });
    // }
  };

  const handleClickOtherBtn = (path: string, pathState?: any) => {
    // setIsBackClick(false);
    // setCurrentPath(path);
    // setCurrentState(pathState)
    // let canJump = currentBreadCrumb?.breadCrumbTitle === 'Edit User' || currentBreadCrumb?.breadCrumbTitle === 'New User'
    // if (localStorage.getItem(RIMS_LOCAL_STG_CONSTANTS.DISABLE_BACK_PREVIOUS) == 'true' && canJump) {
    //   setOpenDialog(true);
    // } else {
    //   localStorage.setItem(RIMS_LOCAL_STG_CONSTANTS.DISABLE_BACK_PREVIOUS, 'false');
      navigate(path, { state: pathState ?? {} });
    // }
  }

  // const handleClickYesBtn = () => {
  //   localStorage.setItem(RIMS_LOCAL_STG_CONSTANTS.DISABLE_BACK_PREVIOUS, 'false');
  //   if (isBackClick) {
  //     navigate(backBreadCrumb.path, { state: backBreadCrumb.breadCrumbState });
  //   } else {
  //     navigate(currentPath, { state: currentState ?? {} });
  //   }
  //   setOpenDialog(false)
  // }

  useEffect(() => {
    setSmartSearch("");
  }, [pathname]);

  const [smartSearch, setSmartSearch] = useState("");
  const navigateTo = useNavigate();
  const handleSmartSearch = () => {
    if (!smartSearch) return;
    navigateTo("/rtq/search-detail", {
      state: {
        params: {
          question: smartSearch,
          filters: {},
          isSemantic: false,
        },
      },
    });
  };

  const inputKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === 13) {
      handleSmartSearch();
    }
  };

  return (
    <div className="bread-crumb">
      <Stack direction={{ xs: "column", sm: "row" }}>
        <Stack spacing={8} direction={{ xs: "column", sm: "row" }}>
          {/* <div className="head-title">
            <Typography variant="h5" sx={{ my: 2 }} className="page-heading">
              {currentBreadCrumb?.canBreadCrumbBack && (
                <IconButton
                  onClick={handleClickBackBtn}
                  sx={{ color: "#294cbb" }}
                >
                  <i className="icon-arrow-left-1"></i>
                </IconButton>
              )}{" "}
              {currentBreadCrumb?.breadCrumbTitle}
            </Typography>
          </div> */}
          <div className="bread-crumb-list">
            {/* <Link to={{ pathname: "/home" }}>
              <i className="icon-home-button"></i>
            </Link> */}
            <span style={{ color: '#1b77e6' }}>Home</span>
            <span className="chevron-right" style={{ marginLeft: '9px' }}></span>
            {/* <i className="icon-angle-double-down arrow"></i> */}
            {
              <ul>
                {breadCrumbs?.map((item: any, index: number) => {
                  return index == breadCrumbs.length - 1 ||
                    (item.children?.[0].path == pathname &&
                      !item.breadCrumbTitle) ? (
                    // Whether each item is unclickable:
                    // last breadCrumb / breadCrumb first child route is the current route and no breadCrumb title
                    <li key={item.menuID}>{item.caption}</li>
                  ) : (
                    <li key={item.menuID}>
                      {/* <Link
                        to={{
                          pathname:
                            !item.path && item.children
                              ? item.children[0].path
                              : item.path,
                        }}
                        state={item.breadCrumbState}
                      >
                        {item.caption}
                      </Link> */}
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleClickOtherBtn(!item.path && item.children ? item.children[0].path : item.path, item.breadCrumbState)
                        }}>
                        {item.caption}
                      </a>
                    </li>
                  );
                })}
              </ul>
            }
          </div>
        </Stack>
        {["Home", "RTQ"].includes(currentBreadCrumb?.caption) && (
          <Stack
            style={{ position: "relative", width: "350px", marginLeft: "8px" }}
            alignSelf="center"
          >
            <TextField
              required
              size="small"
              value={smartSearch}
              label=""
              placeholder="Smart Search"
              onChange={(e) => setSmartSearch(e.target.value)}
              onKeyUp={inputKeyUp}
            />
            <i
              onClick={handleSmartSearch}
              className="icon-search"
              style={{ position: "absolute", left: "270px", top: "7px" }}
            ></i>
          </Stack>
        )}
      </Stack>
      {/* <CommonDialog openDialog={openDialog} title={'Unsaved Changes'} content={'Are you sure you want to leave this page? Any unsaved changes will be lost.'} isDeleteDialog={false} handlePrimaryBtn={() => handleClickYesBtn()} handleSecondaryBtn={() => setOpenDialog(false)} primaryBtnName={'Yes'} secondaryBtnName={'No'}>
      </CommonDialog> */}
    </div>
  );
}
