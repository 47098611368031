export type Menus = {
  menuID: number;
  iconfont: string;
  caption: string; // BreadCrumbName
  path: string;
  parID: number;
  lvl: number;
  visible?: boolean; // Whether the sideMenu is visible, Nothing to do with breadCrumb
  children?: Menus[];
  breadCrumbTitle?: string; // BreadCrumbTitle
  breadCrumbState?: any; // Breadcrumbs jump to the required state
  canBreadCrumbBack?: boolean; // Whether BreadCrumbTitle can be jumped
  disclickabled?: boolean;
};

export const getMenuList = (
  authList: any,
  roleTypeList: any,
  state: any
): Menus[] => {
  // console.log(state);
  let routeList = [
    // {
    //   breadCrumbTitle: "Products",
    //   menuID: 2,
    //   iconfont: "icon icon-text-list-file2",
    //   caption: "Products",
    //   path: "/products",
    //   parID: 0,
    //   visible: authList.includes("products_view"),
    //   children: [
    //     {
    //       breadCrumbTitle: "New Product",
    //       canBreadCrumbBack: true,
    //       menuID: 201,
    //       iconfont: "",
    //       caption: "Add",
    //       path: "/products/add-product",
    //       parID: 2,
    //       lvl: 2,
    //     },
    //   ]
    // },
    {
      breadCrumbTitle: "User Authentication",
      menuID: 7,
      iconfont: "icon icon-security",
      caption: "User Authentication",
      path: "/user-access-management/users",
      parID: 0,
      visible: authList.includes("admin_view"),
      children: [
        // {
        //   breadCrumbTitle: "",
        //   menuID: 711,
        //   iconfont: "",
        //   caption: "User Access Management",
        //   path: "/user-access-management",
        //   parID: 7,
        //   visible: authList.includes("admin_view_UAM_view"),
        //   children: [],
        //   lvl: 2,
        // },
        {
          breadCrumbTitle: "Import User",
          canBreadCrumbBack: true,
          menuID: 7110101,
          iconfont: "",
          caption: "Import",
          path: "/user-access-management/import-user",
          parID: 71101,
          lvl: 2,
        },
        {
          breadCrumbTitle: "Add User",
          canBreadCrumbBack: true,
          menuID: 7110102,
          iconfont: "",
          caption: "Add",
          path: "/user-access-management/add-user",
          parID: 71101,
          lvl: 2,
        },
        {
          breadCrumbTitle: "Edit User",
          canBreadCrumbBack: true,
          menuID: 7110103,
          iconfont: "",
          caption: "Edit",
          path: "/user-access-management/edit-user",
          parID: 71101,
          lvl: 2,
        },
        {
          breadCrumbTitle: "View User",
          canBreadCrumbBack: true,
          menuID: 7110104,
          iconfont: "",
          caption: "View",
          path: "/user-access-management/view-user",
          parID: 71101,
          lvl: 2,
        },
        {
          breadCrumbTitle: "Reassign",
          canBreadCrumbBack: true,
          menuID: 7110105,
          iconfont: "",
          caption: "Reassign",
          path: "/user-access-management/reassign-user",
          parID: 71101,
          lvl: 2,
        },
      ],
      lvl: 1,
    },
  ];

  // if (authList.includes('admin_view_UAM_view_users_view')) {
  //   // @ts-ignore 
  //   routeList[0].children[0].children.push({
  //     breadCrumbTitle: "Users",
  //     menuID: 71101,
  //     iconfont: "",
  //     caption: "Users",
  //     path: "/user-access-management/users",
  //     parID: 711,
  //     children: [
  //       {
  //         breadCrumbTitle: "Import User",
  //         canBreadCrumbBack: true,
  //         menuID: 7110101,
  //         iconfont: "",
  //         caption: "Import",
  //         path: "/user-access-management/import-user",
  //         parID: 71101,
  //         lvl: 4,
  //       },
  //       {
  //         breadCrumbTitle: "Add User",
  //         canBreadCrumbBack: true,
  //         menuID: 7110102,
  //         iconfont: "",
  //         caption: "Add",
  //         path: "/user-access-management/add-user",
  //         parID: 71101,
  //         lvl: 4,
  //       },
  //       {
  //         breadCrumbTitle: "Edit User",
  //         canBreadCrumbBack: true,
  //         menuID: 7110103,
  //         iconfont: "",
  //         caption: "Edit",
  //         path: "/user-access-management/edit-user",
  //         parID: 71101,
  //         lvl: 4,
  //       },
  //       {
  //         breadCrumbTitle: "View User",
  //         canBreadCrumbBack: true,
  //         menuID: 7110104,
  //         iconfont: "",
  //         caption: "View",
  //         path: "/user-access-management/view-user",
  //         parID: 71101,
  //         lvl: 4,
  //       },
  //       {
  //         breadCrumbTitle: "Reassign",
  //         canBreadCrumbBack: true,
  //         menuID: 7110105,
  //         iconfont: "",
  //         caption: "Reassign",
  //         path: "/user-access-management/reassign-user",
  //         parID: 71101,
  //         lvl: 4,
  //       },
  //     ],
  //     lvl: 3,
  //   })
  // }
  // if (authList.includes('admin_view_UAM_view_roles_view')) {
  //   // @ts-ignore 
  //   routeList[0].children[0].children.push({
  //     breadCrumbTitle: "Roles",
  //     menuID: 71102,
  //     iconfont: "",
  //     caption: "Roles",
  //     path: "/user-access-management/roles",
  //     parID: 711,
  //     children: [
  //       {
  //         breadCrumbTitle: "Import Role",
  //         canBreadCrumbBack: true,
  //         menuID: 7110201,
  //         iconfont: "",
  //         caption: "Import",
  //         path: "/user-access-management/import-role",
  //         parID: 71102,
  //         lvl: 4,
  //       },
  //       {
  //         breadCrumbTitle: "Add Role",
  //         canBreadCrumbBack: true,
  //         menuID: 7110202,
  //         iconfont: "",
  //         caption: "Add",
  //         path: "/user-access-management/add-role",
  //         parID: 71102,
  //         lvl: 4,
  //       },
  //       {
  //         breadCrumbTitle: "Edit Role",
  //         canBreadCrumbBack: true,
  //         menuID: 7110203,
  //         iconfont: "",
  //         caption: "Edit",
  //         path: "/user-access-management/edit-role",
  //         parID: 71102,
  //         lvl: 4,
  //       },
  //       {
  //         breadCrumbTitle: "View Role",
  //         canBreadCrumbBack: true,
  //         menuID: 7110204,
  //         iconfont: "",
  //         caption: "View",
  //         path: "/user-access-management/view-role",
  //         parID: 71102,
  //         lvl: 4,
  //       },
  //     ],
  //     lvl: 3,
  //   })
  // }
  // if (authList.includes('admin_view_UAM_view_groups_view')) {
  //   // @ts-ignore 
  //   routeList[0].children[0].children.push({
  //     breadCrumbTitle: "User Group",
  //     menuID: 71103,
  //     iconfont: "",
  //     caption: "User Group",
  //     path: "/user-access-management/user-groups",
  //     parID: 711,
  //     children: [
  //       {
  //         breadCrumbTitle: "Import User Group",
  //         canBreadCrumbBack: true,
  //         menuID: 7110301,
  //         iconfont: "",
  //         caption: "Import",
  //         path: "/user-access-management/import-user-group",
  //         parID: 71103,
  //         lvl: 4,
  //       },
  //       {
  //         breadCrumbTitle: "Add User Group",
  //         canBreadCrumbBack: true,
  //         menuID: 7110302,
  //         iconfont: "",
  //         caption: "Add",
  //         path: "/user-access-management/add-user-group",
  //         parID: 71103,
  //         lvl: 4,
  //       },
  //       {
  //         breadCrumbTitle: "Edit User Group",
  //         canBreadCrumbBack: true,
  //         menuID: 7110303,
  //         iconfont: "",
  //         caption: "Edit",
  //         path: "/user-access-management/edit-user-group",
  //         parID: 71103,
  //         lvl: 4,
  //       },
  //       {
  //         breadCrumbTitle: "View User Group",
  //         canBreadCrumbBack: true,
  //         menuID: 7110304,
  //         iconfont: "",
  //         caption: "View",
  //         path: "/user-access-management/view-user-group",
  //         parID: 71103,
  //         lvl: 4,
  //       },
  //     ],
  //     lvl: 3,
  //   })
  // }
  // if (authList.includes('admin_view_UAM_view_acls_view')) {
  //   // @ts-ignore 
  //   routeList[0].children[0].children.push({
  //     breadCrumbTitle: "Access Control",
  //     menuID: 71104,
  //     iconfont: "",
  //     caption: "Access Control",
  //     path: "/user-access-management/acl",
  //     parID: 711,
  //     children: [
  //       {
  //         breadCrumbTitle: "Import Access Control",
  //         canBreadCrumbBack: true,
  //         menuID: 7110401,
  //         iconfont: "",
  //         caption: "Import",
  //         path: "/user-access-management/import-acl",
  //         parID: 71104,
  //         lvl: 4,
  //       },
  //       {
  //         breadCrumbTitle: "Add Access Control",
  //         canBreadCrumbBack: true,
  //         menuID: 7110402,
  //         iconfont: "",
  //         caption: "Add",
  //         path: "/user-access-management/add-acl",
  //         parID: 71104,
  //         lvl: 4,
  //       },
  //       {
  //         breadCrumbTitle: "Edit Access Control",
  //         canBreadCrumbBack: true,
  //         menuID: 7110403,
  //         iconfont: "",
  //         caption: "Edit",
  //         path: "/user-access-management/edit-acl",
  //         parID: 71104,
  //         lvl: 4,
  //       },
  //       {
  //         breadCrumbTitle: "View Access Control",
  //         canBreadCrumbBack: true,
  //         menuID: 7110404,
  //         iconfont: "",
  //         caption: "View",
  //         path: "/user-access-management/view-acl",
  //         parID: 71104,
  //         lvl: 4,
  //       }
  //     ]
  //   })
  // }
  // // @ts-ignore 
  // routeList[0].children[0].path = routeList[0].children[0].children[0]?.path;

  return routeList;
};
