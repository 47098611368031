import axios from "axios";
import SnackbarUtils from "../components/NotistackPlus";
import { RIMS_LOCAL_STG_CONSTANTS } from "../constants";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { logoutFunction } from "../common/utils";
import cookie from 'react-cookies'
// import { toast } from 'react-toastify';

// const isPrd = process.env.NODE_ENV === 'production';

//export const basicUrl = isPrd ? 'https://88xqd6km6b.execute-api.us-east-1.amazonaws.com' : 'http://localhost:3000/api'

export const basicUrl = process.env.REACT_APP_API_GETWAY;
const refreshURL = '/common-auth/refresh';
const redirectURL = process.env.REACT_APP_REDIRECT_URL;

const service = axios.create({
  baseURL: basicUrl,
});

service.interceptors.request.use(
  (config) => {
    let token = cookie.load(RIMS_LOCAL_STG_CONSTANTS.LOGIN_TOKEN);
    // let token = 'eyJraWQiOiIzelV4eHVkNEx4NElEakFhdmhhcUlBQzJIR0pEaHRoajZvM1IzVUs3NlQ0PSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIzZTE4OWY3Mi1hMzlhLTQ2NjItODNiYS0zNmQ3OTBkZDMwMzMiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9rM0E0UkZyVGUiLCJjbGllbnRfaWQiOiI3NmowMDdtOG02MnZ2ZXQ2amVjODNkMnVkIiwib3JpZ2luX2p0aSI6ImI1ODcwMzkwLTBjNmYtNDk5Ni04NmU2LWUxZTY3MDUwZGJhNSIsImV2ZW50X2lkIjoiZmViM2Q5NzAtN2YyNC00NDIwLTlhNGQtY2UzYjFlM2E4YjY1IiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTY5NDA2NDU5NywiZXhwIjoxNjk0MTUwOTk3LCJpYXQiOjE2OTQwNjQ1OTcsImp0aSI6ImFhNzg2MjFkLTY1ODUtNDIxMC05ZjY3LTgzYmU3ZDJmMjQxNCIsInVzZXJuYW1lIjoiYWRtaW4ifQ.z2IrZFy2yeQNbRdCCRLijTwVjBibWBJnjIfYh_fjcp0ULcmwfyQkp_kOBiaU7QFxw5AnmKY8UXEhABHnMZoBvGVp5X4ECcsCPBk5RezJ0pPICkXECerRezu7ib9sYk8Vad1BugCgQ8qq_kZdMNlEHOxRI2y6tjARfigQ_eFOJbI26BdfvV6fRF65Mqqyp68IKnjsj9JhwLqrAV_8PlAAau9IaSUCKwqR4PMuXGNZpW4-JNZLugmaBoVyepx-QS3VqJEDTwh09y9UvEWpgXFehSee-VCHdVL8ri5WHVNHKgVDWRXZjxKZaKri3J4c1QB3w4fAwW1k0_rulAf8BjMJtA'
    // // @ts-ignore
    // if (!token && config.url?.indexOf(AUTH_PREFIX) === -1) {
    //   window.location.href = `${redirectURL}?redirect=${window.location.origin}`;
    // }
    config.withCredentials = true;
    token
      ? (config.headers = {
          "Content-Type": (config.responseType === 'arraybuffer' && JSON.stringify(config.data) === '{}') ? "application/octet-stream;charset=utf-8" : "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`,
        })
      : (config.headers = {
          "Content-Type": "application/json;charset=utf-8",
        });
    // : (config.headers = {
    //     "Content-Type": "application/json;charset=utf-8",
    //   });
    // config.data = QS.stringify(config.data)
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response: any) => {
    if (response.config.responseType !== 'arraybuffer') {
      if (response.status) {
        switch (response.status) {
          case 200:
            return response.data;
          case 400:
          case 401:
          case 403:
            if (response.config?.url?.indexOf(refreshURL) > -1) {
              logoutFunction();
              window.location.href = window.location.origin;
            }
            return;
          default:
            console.log("error", response.data.msg);
        }
      }
    } else {
      return response;
    }
  },
  (err) => {
    if (err.config.url.indexOf(refreshURL) > -1) {
      logoutFunction();
      window.location.href = `${redirectURL}?redirect=${window.location.origin}`;
    }
    switch (err.response.status) {
      case 401:
        break;
      case 403:
        break;
      case 500:
        if (cookie.load(RIMS_LOCAL_STG_CONSTANTS.LOGIN_TOKEN)) {
          SnackbarUtils.error(
            "There is something wrong with server, please try again later"
          );
        }
        break;
      default:
      // SnackbarUtils.error('There is something wrong with server, please try again later')
    }
    return Promise.reject(err);
  }
);

const refreshAuthLogic = (failedRequest: any) => {
  const userInfoJson: any = cookie.load(
    RIMS_LOCAL_STG_CONSTANTS.USER_INFO
  );
  const userInfo: any =
    typeof userInfoJson === "string" ? JSON.parse(userInfoJson) : null;
  // If there is no token exist, return to login page
  if (!userInfo) {
    logoutFunction();
    window.location.href = `${redirectURL}?redirect=${window.location.origin}`;
    return Promise.resolve();
  }
  return service
    .post(refreshURL, {
      username: userInfo?.username,
      refreshToken: userInfo?.refreshToken,
    })
    .then((response: any) => {
      console.log(response);
      if (response.status == '401' || response.status == '400') {
        logoutFunction();
        window.location.href = `${redirectURL}?redirect=${window.location.origin}`;
      } else {
        window.localStorage.setItem(
          RIMS_LOCAL_STG_CONSTANTS.LOGIN_TOKEN,
          response.data.accessToken
        );
        window.localStorage.setItem(
          RIMS_LOCAL_STG_CONSTANTS.USER_INFO,
          JSON.stringify(response.data)
        );
        failedRequest.response.config.headers["Authorization"] =
          "Bearer " + response.data.accessToken;
      }
      return Promise.resolve();
    })
    .catch((error: any) => {
      logoutFunction();
      window.location.href = `${redirectURL}?redirect=${window.location.origin}`;
      return Promise.reject(error);
    });
};

createAuthRefreshInterceptor(service, refreshAuthLogic);

export default service;
