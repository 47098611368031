import * as ActionTypes from '../../actionTypes'

const initialState = {
  userList: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: any) => {
  const { userList, type } = action
  switch (type) {
    case ActionTypes.UPDATE_USER_LIST:
      return { ...state, userList: userList }
    default:
      return state
  }
}