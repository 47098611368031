import { lazy } from 'react';
import { Navigate, useRoutes, createBrowserRouter } from 'react-router-dom';
import AddNewAcl from '../pages/admin/acl/AddNewAcl';
import NewAclsList from '../pages/admin/acl/NewAclsList';

import AdminModule from '../pages/admin/index';
import UAMIndex from '../pages/admin/UAMIndex';

const Login = lazy(() => import('../pages/login/Login'));
const CommonLogin = lazy(() => import('../pages/login/CommonLogin'));
const VerifyCode = lazy(() => import('../pages/login/VerifyCode'));
const MFActive = lazy(() => import('../pages/login/MFActive'));
const ForgotPassword = lazy(() => import('../pages/login/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/login/ResetPassword'));
const ActivateAccount = lazy(() => import('../pages/login/ActivateAccount'));

const UserList = lazy(() => import('../pages/admin/user/UserList'));
const GroupList = lazy(() => import('../pages/admin/group/GroupList'));
const RoleList = lazy(() => import('../pages/admin/role/RoleList'));
const AddUser = lazy(() => import('../pages/admin/user/AddUser'));
const ViewUser = lazy(() => import('../pages/admin/user/ViewUser'));
const AddGroup = lazy(() => import('../pages/admin/group/AddGroup'));
const ViewGroup = lazy(() => import('../pages/admin/group/ViewGroup'));
const AddRole = lazy(() => import('../pages/admin/role/AddRole'));
const ViewRole = lazy(() => import('../pages/admin/role/ViewRole'));

const routesForm = [
  {
    path: '/common-login',
    element: <CommonLogin />
  },
  {
    path: '/verify-code',
    element: <VerifyCode />
  },
  {
    path: '/mfa-active',
    element: <MFActive />
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />
  },
  {
    path: '/activate-account',
    element: <ActivateAccount />
  },
  {
    path: '/reset-password',
    element: <ResetPassword />
  },

  {
    path: '/user-access-management',
    element: <AdminModule />,
    children: [
      {
        path: 'users',
        element: <UserList />
      },
      {
        path: 'add-user',
        element: <AddUser />
      },
      {
        path: 'edit-user',
        element: <AddUser />
      },
      {
        path: 'view-user',
        element: <ViewUser />
      },
      {
        path: 'add-user-group',
        element: <AddGroup />
      },
      {
        path: 'edit-user-group',
        element: <AddGroup />
      },
      {
        path: 'view-user-group',
        element: <ViewGroup />
      },
      {
        path: '',
        element: <UserList />
      }
    ]
  },
  // {
  //   path: '/user-access-management',
  //   element: <AdminModule />,
  //   children: [
  //     {
  //       path: '',
  //       element: <UAMIndex />,
  //       children: [
  //         {
  //           path: '',
  //           element: <Navigate to='users' />
  //         },
  //         {
  //           path: 'acl',
  //           element: <NewAclsList />
  //         },
  //         {
  //           path: 'add-acl',
  //           element: <AddNewAcl />
  //         },
  //         {
  //           path: 'edit-acl',
  //           element: <AddNewAcl />
  //         },
  //         {
  //           path: 'view-acl',
  //           element: <AddNewAcl />
  //         },
  //         {
  //           path: 'user-groups',
  //           element: <GroupList />
  //         },
  //         {
  //           path: 'roles',
  //           element: <RoleList />
  //         },
  //         {
  //           path: 'add-role',
  //           element: <AddRole />
  //         },
  //         {
  //           path: 'edit-role',
  //           element: <AddRole />
  //         },
  //         {
  //           path: 'view-role',
  //           element: <ViewRole />
  //         },
  //         {
  //           path: 'users',
  //           element: <UserList />
  //         },
  //         {
  //           path: 'add-user',
  //           element: <AddUser />
  //         },
  //         {
  //           path: 'edit-user',
  //           element: <AddUser />
  //         },
  //         {
  //           path: 'view-user',
  //           element: <ViewUser />
  //         },
  //         {
  //           path: 'add-user-group',
  //           element: <AddGroup />
  //         },
  //         {
  //           path: 'edit-user-group',
  //           element: <AddGroup />
  //         },
  //         {
  //           path: 'view-user-group',
  //           element: <ViewGroup />
  //         },
  //         {
  //           path: '',
  //           element: <UserList />
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    path: '/*',
    element: <Navigate to='/user-access-management/users' />
  },
]

const Router = createBrowserRouter(routesForm);

export { Router }