import service from './index'

const prefix = '/audit/log-record';

// ACL
export const fetchAuditList = (params: any) => service.post(`${prefix}/list`, params);

export const fetchAuditFieldModification = (auditId: string) => service.get(prefix + `/one/modification-info?auditId=${auditId}`);

export const fetchOperationListByEntityId = (entityId: string, fromService: string, pageNum: number, pageSize: number) => service.get(prefix + `/entity/operation-list?entityId=${entityId}&fromService=${fromService}&pageNum=${pageNum}&pageSize=${pageSize}`);

export const EXPORT_AUDIT_LOG_URL = `${prefix}/export`;

export const EXPORT_AUDIT_ENTITY_IDENTIFIER_URL = `${prefix}/export/entity/operation-list`