export const RIMS_CONSTANTS = {

}

export const RIMS_LOCAL_STG_CONSTANTS = {
    LOGIN_TOKEN: 'LOGIN_TOKEN',
    USER_INFO: 'USER_INFO',
    MENU_AUTH_LIST: 'MENU_AUTH_LIST',
    DATA_AUTH_LIST: 'DATA_AUTH_LIST',
    SESSION_TIME_OUT: 'SESSION_TIME_OUT',
    DISABLE_BACK_PREVIOUS: 'DISABLE_BACK_PREVIOUS',
    EMAIL: 'EMAIL'
}

export const RIMS_SESSION_STG_CONSTANTS = {
    MODULE_NAME: 'MODULE_NAME',
    PATH_NAME: 'PATH_NAME'
}

export const REGEXP = {
    accountNo: /^[0-9A-Za-z]{4,10}$/,
    desPhoneNum: /(\d{3})\d*(\d{4})/
}

export const RTQ_EVENTS = {
    ADMIN: {
        CLOSE_EXPORT_LIST_MODAL: 'CLOSE_EXPORT_LIST_MODAL',
        CLOSE_STATUS_UPDATE_MODAL: 'CLOSE_STATUS_UPDATE_MODAL',
        CLOSE_UPDATE_STATUS_HISTORY_MODAL: 'CLOSE_UPDATE_STATUS_HISTORY_MODAL',
        CLOSE_AUDIT_MODAL: 'CLOSE_AUDIT_MODAL',
        DOWNLOAD_TEMPLATE_START: 'DOWNLOAD_TEMPLATE_START',
        DOWNLOAD_TEMPLATE_END: 'DOWNLOAD_TEMPLATE_END'
    },
    PAGINATION: {
        CHANGE_VALUE: 'CHANGE_VALUE'
    },
    RTQ: {
        CLOSE_QUESTION_MODAL: 'CLOSE_QUESTION_MODAL',
        CLOSE_RESPONSE_MODAL: 'CLOSE_RESPONSE_MODAL',
        CLOSE_SIDE_SEARCH_DRAWER: 'CLOSE_SIDE_SEARCH_DRAWER'
    },
    COMMON: {
        EXPORT_FILE_START: 'EXPORT_FILE_START',
        EXPORT_FILE_END: 'EXPORT_FILE_END'
    },
    HEADER: {
        TOGGLE_MENU_EXTEND: 'TOGGLE_MENU_EXTEND',
        TOGGLE_MENU_COLLAPSED: 'TOGGLE_MENU_COLLAPSED'
    }
}


export const enum ModeEnum {
    READ = 'read',
    UPDATE = 'update',
    CREATE = 'create'
}

export const pathAuthMapping = new Map([
    ["/products", "products_view"],
    ["/products/add-product", "products_add"],
    ["/products/edit-product", "products_edit"],
    ["/products/view-product", "products_view"],
    ["/rtq/queries", "rtq_view"],
    ["/rtq/edit-query", "rtq_edit"],
    ["/rtq/view-query", "rtq_view"],
    ["/rtq/add-query", "rtq_add"],
    ["/user-access-management", "admin_view_UAM_view_users_view"],
    ["/user-access-management/users", "admin_view_UAM_view_users_view"],
    ["/user-access-management/import-user", "admin_view_UAM_view_users_add"],
    ["/user-access-management/add-user", "admin_view_UAM_view_users_add"],
    ["/user-access-management/edit-user", "admin_view_UAM_view_users_edit"],
    ["/user-access-management/view-user", "admin_view_UAM_view_users_view"],
    ["/user-access-management/reassign-user", "admin_view_UAM_view_users_edit"],
    ["/user-access-management/roles", "admin_view_UAM_view_roles_view"],
    ["/user-access-management/import-role", "admin_view_UAM_view_roles_add"],
    ["/user-access-management/add-role", "admin_view_UAM_view_roles_add"],
    ["/user-access-management/edit-role", "admin_view_UAM_view_roles_edit"],
    ["/user-access-management/view-role", "admin_view_UAM_view_roles_view"],
    ["/user-access-management/user-groups", "admin_view_UAM_view_groups_view"],
    ["/user-access-management/import-user-group", "admin_view_UAM_view_groups_add"],
    ["/user-access-management/add-user-group", "admin_view_UAM_view_groups_add"],
    ["/user-access-management/edit-user-group", "admin_view_UAM_view_groups_edit"],
    ["/user-access-management/view-user-group", "admin_view_UAM_view_groups_view"],
    ["/user-access-management/acl", "admin_view_UAM_view_acls_view"],
    ["/user-access-management/add-acl", "admin_view_UAM_view_acls_add"],
    ["/user-access-management/edit-acl", "admin_view_UAM_view_acls_edit"],
    ["/user-access-management/view-acl", "admin_view_UAM_view_acls_view"],
    ["/user-access-management/import-acl", "admin_view_UAM_view_acls_add"],
    ["/ingestion/inbound-requests", "cem_view_inbound_view"],
    ["/ingestion/view-inbound-record", "cem_view_inbound_view"],
    ["/ingestion/edit-inbound-record", "cem_view_inbound_edit"],
    ['/ingestion/add-inbound-request', "cem_view_inbound_edit"],
    ["/ingestion/inbound-records/extracted-contents", "cem_view_extract_view"],
    ["/ingestion/documents", "cem_view_documents_view"],
    ["/ingestion/extracted-contents", "cem_view_extract_view"],
    ["/ingestion/view-extracted-content", "cem_view_documents_view"],
    ["/ingestion/view-extracted-content", "cem_view_extract_view"],
    ["/report/general-reports", "reports_view_generalReports_view"],
    ["/report/audit-reports", "reports_view_auditReports_view"],
    ["/report/custom-reports", "reports_view_quickSightReports_view"],
    ["/report/custom-reports/report", "reports_view_quickSightReports_view"],
    ["/admin/category", "admin_view_systemLibs_view"],
    ["/admin/business-unit", "admin_view_systemLibs_view"],
    ["/admin/country-group", "admin_view_systemLibs_view"],
    ["/admin/country-group-mapping", "admin_view_systemLibs_view"],
    ["/admin/department", "admin_view_systemLibs_view"],
    ["/admin/function", "admin_view_systemLibs_view"],
    ["/admin/health-authority", "admin_view_systemLibs_view"],
    ["/admin/health-authority-mapping", "admin_view_systemLibs_view"],
    ["/admin/organization", "admin_view_systemLibs_view"],
    ["/admin/sub-category", "admin_view_systemLibs_view"],
    ["/admin/tenant", "admin_view_systemLibs_view"],
    ["/admin/topic", "admin_view_systemLibs_view"],
])

export const pathAuthWhiteList = [
    '/admin/system-setting',
    '/home',
    '/admin/auditList',
    '/rtq/smart-search',
    '/rtq/search-detail',
    '/rtq/view-qa-detail'
]

export const MFA_QR_URL = 'otpauth://totp/RTQ:{email}?secret={secret}&issuer=RTQ'

export const UAM_MENU_LIST = [
    {
        name: "Users",
        link: "/user-access-management/users",
    },
    {
        name: "Roles",
        link: "/user-access-management/roles",
    },
    {
        name: "User Groups",
        link: "/user-access-management/user-groups",
    },
    {
        name: "Access Control",
        link: "/user-access-management/acl",
    },
];

export const COOKIE_SETUP = {
    path: "/",
    domain: process.env.REACT_APP_STG_DOMAIN,
}