import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reducer from './reducer'

const configureStore = (): Store<any> => {
  const middlewares: any[] = [thunk];
  const enhancer = composeWithDevTools(applyMiddleware(...middlewares));
  return createStore(reducer, enhancer);
};

const store = configureStore();

export default store;

