import './App.scss';
import { RouterProvider } from 'react-router-dom';
import { Router } from './routes/route';

function App() {
  return (
    <div className="rtq-app">
      <RouterProvider router={Router} />
    </div>
  );
}

export default App;
