import { Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import axios from "axios";
import { Suspense, useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { isExpired } from "react-jwt";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import cookie from 'react-cookies'
import {
  getRelatedMenuResourceList,
  getSpecifyRelatedResourceList,
  getSystemSettings,
} from "../../api/admin";
import { logoutFunction } from "../../common/utils";
import AppHeader from "../../components/AppHeader";
import Loading from "../../components/Loading";
import {
  RIMS_LOCAL_STG_CONSTANTS,
  pathAuthMapping,
  pathAuthWhiteList,
} from "../../constants";
import BreadCrumb from '../../components/BreadCrumb';
import { Menus, getMenuList } from '../../components/menuList';
import { withAuth } from '../../components/AuthWrapper';

const Homepage = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(false);
  const promptBeforeIdle = 30 * 1000;
  const [timeout, setTimeout] = useState(60 * 10000);
  const [remaining, setRemaining] = useState<number>(timeout);
  const [open, setOpen] = useState<boolean>(false);

  const onIdle = () => {
    setOpen(false);
    logoutFunction();
    window.location.href = `${redirectURL}?redirect=${window.location.origin}`;
  };

  const onActive = () => {
    setOpen(false);
  };

  const onPrompt = () => {
    setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    crossTab: true,
    syncTimers: 200,
  });

  const handleStillHere = () => {
    activate();
  };

  const [menuList, setMenuList] = useState<Menus[]>([]);
  const [openMenuList, setOpenMenuList] = useState<Menus[]>([]);
  const { pathname, state } = useLocation();
  const [roleTypeList, setRoleTypeList] = useState<any>(['']);
  const redirectURL = process.env.REACT_APP_REDIRECT_URL;
  const authList = ["admin_view_UAM_view_acls_add", "rtq_edit_documents_add", "products_add", "rtq_edit_QRDetail_add", "admin_view_UAM_view_roles_add", "rtq_add", "admin_view_systemLibs_add", "admin_view_UAM_view_groups_add", "admin_view_UAM_view_users_add", "admin_view_UAM_view_acls_delete", "rtq_edit_documents_delete", "products_delete", "rtq_edit_QRDetail_delete", "admin_view_UAM_view_roles_delete", "rtq_delete", "admin_view_systemLibs_delete", "admin_view_UAM_view_groups_delete", "admin_view_UAM_view_users_delete", "admin_view_UAM_view_acls_edit", "rtq_edit_basicInfo_edit", "rtq_edit_documents_edit", "products_edit", "rtq_edit_QRDetail_edit", "admin_view_UAM_view_roles_edit", "rtq_edit", "rtq_edit_submissions_edit", "admin_view_systemLibs_edit", "admin_view_UAM_view_groups_edit", "admin_view_UAM_view_users_edit", "admin_view_UAM_view_acls_view", "admin_view", "reports_view_auditReports_view", "rtq_view_basicInfo_view", "rtq_edit_basicInfo_view", "rtq_view_categoryGroup_view", "rtq_edit_categoryGroup_view", "reports_view_quickSightReports_view", "rtq_view_documents_view", "rtq_edit_documents_view", "reports_view_generalReports_view", "rtq_view_product_view", "rtq_edit_product_view", "products_view", "rtq_view_QRDetail_view", "rtq_edit_QRDetail_view", "reports_view", "admin_view_UAM_view_roles_view", "rtq_view", "rtq_view_submissions_view", "rtq_edit_submissions_view", "admin_view_systemLibs_view", "admin_view_UAM_view", "admin_view_UAM_view_groups_view", "admin_view_UAM_view_users_view", "rtq_edit_workflow_view"];

  const getOpenMenuList = (data: Menus[], path: string): any => {
    for (let i = 0; i < data.length; i++) {
      if (data[i] && data[i].path == path) {
        return [data[i]];
      }
      if (data[i] && data[i].children) {
        let d = getOpenMenuList(data[i].children || [], path);
        if (d) return d.concat(data[i]);
      }
    }
  };

  useEffect(() => {
    const openMenus = getOpenMenuList(menuList, pathname)?.reverse() || [];
    setOpenMenuList(openMenus);
  }, [pathname, menuList]);

  useEffect(() => {
    const menus = getMenuList(authList, roleTypeList, state);
    setMenuList(menus);
  }, []);

  useEffect(() => {
    const whiteEmail = decodeURIComponent(process.env.REACT_APP_EMAIL_WHITE_LIST || '');
    let blLogin = cookie.load(RIMS_LOCAL_STG_CONSTANTS.LOGIN_TOKEN);
    const email = cookie.load(RIMS_LOCAL_STG_CONSTANTS.EMAIL);
    if (blLogin === null || isExpired(blLogin) || email !== whiteEmail) {
      setAuth(false);
      logoutFunction();
      window.location.href = `${redirectURL}?redirect=${window.location.origin}`;
    } else {
      setAuth(true);
    }
    // setAuth(true);
  }, []);
  return (
    <>
      {auth && (
        <>
          <AppHeader />
          <div className="page-wrapper">
            <div className="customer-page">
              <Suspense fallback={<Loading open={true} />}>
                <BreadCrumb openMenuList={openMenuList}></BreadCrumb>
                <Outlet />
              </Suspense>
            </div>
          </div>
        </>
      )}
      <Loading open={loading} />
    </>
  );
};

export default withAuth(Homepage);
