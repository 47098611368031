import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/UI-kit/styles.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import store from "./store";
import { SnackbarUtilsConfigurator } from './components/NotistackPlus';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <Provider store={store}>
    <SnackbarProvider maxSnack={1} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <SnackbarUtilsConfigurator />
      <App />
    </SnackbarProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
