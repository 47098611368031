import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { useSnackbar } from 'notistack';
import { memo, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import service, { basicUrl } from "../api";
import { UAM_PREFIX, judgeUserHasSignaturePassword, testResource2 } from '../api/admin';
import { logout } from '../api/login';
import { SYSLIB_PREFIX } from '../api/systemLibrary';
import eventBus from '../common/eventBus';
import { firstWordCase, logoutFunction } from '../common/utils';
import { COOKIE_SETUP, RIMS_LOCAL_STG_CONSTANTS, RIMS_SESSION_STG_CONSTANTS, RTQ_EVENTS } from '../constants';
import ChangePswDialog from "./ChangePswDialog";
import Loading from './Loading';
import { withAuth } from './AuthWrapper';
import { EXPORT_AUDIT_LOG_URL } from '../api/audit';
import BreadCrumb from './BreadCrumb';
import { Menus, getMenuList } from './menuList';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CommonDialog from './CommonDialog';
import cookie from 'react-cookies'

function AppHeader(props: any) {
  const { enqueueSnackbar } = useSnackbar();
  const authList = ["admin_view_UAM_view_acls_add", "rtq_edit_documents_add", "products_add", "rtq_edit_QRDetail_add", "admin_view_UAM_view_roles_add", "rtq_add", "admin_view_systemLibs_add", "admin_view_UAM_view_groups_add", "admin_view_UAM_view_users_add", "admin_view_UAM_view_acls_delete", "rtq_edit_documents_delete", "products_delete", "rtq_edit_QRDetail_delete", "admin_view_UAM_view_roles_delete", "rtq_delete", "admin_view_systemLibs_delete", "admin_view_UAM_view_groups_delete", "admin_view_UAM_view_users_delete", "admin_view_UAM_view_acls_edit", "rtq_edit_basicInfo_edit", "rtq_edit_documents_edit", "products_edit", "rtq_edit_QRDetail_edit", "admin_view_UAM_view_roles_edit", "rtq_edit", "rtq_edit_submissions_edit", "admin_view_systemLibs_edit", "admin_view_UAM_view_groups_edit", "admin_view_UAM_view_users_edit", "admin_view_UAM_view_acls_view", "admin_view", "reports_view_auditReports_view", "rtq_view_basicInfo_view", "rtq_edit_basicInfo_view", "rtq_view_categoryGroup_view", "rtq_edit_categoryGroup_view", "reports_view_quickSightReports_view", "rtq_view_documents_view", "rtq_edit_documents_view", "reports_view_generalReports_view", "rtq_view_product_view", "rtq_edit_product_view", "products_view", "rtq_view_QRDetail_view", "rtq_edit_QRDetail_view", "reports_view", "admin_view_UAM_view_roles_view", "rtq_view", "rtq_view_submissions_view", "rtq_edit_submissions_view", "admin_view_systemLibs_view", "admin_view_UAM_view", "admin_view_UAM_view_groups_view", "admin_view_UAM_view_users_view", "rtq_edit_workflow_view"];
  const [username, setUsername] = useState('');
  const [authMechanism, setAuthMechanism] = useState('');
  const [roleTypeList, setRoleTypeList] = useState<any>(['']);
  const { pathname, state } = useLocation();
  const [menuList, setMenuList] = useState<Menus[]>([]);
  const [openMenuList, setOpenMenuList] = useState<Menus[]>([]);

  // const [openDialog, setOpenDialog] = useState(false);
  // const [currentClickedPath, setCurrentClickedPath] = useState('/home');

  // const getSessionMenuInfo = () => {
  //   const moduleName = window.sessionStorage.getItem(RIMS_SESSION_STG_CONSTANTS.MODULE_NAME)
  //   const pathName = window.sessionStorage.getItem(RIMS_SESSION_STG_CONSTANTS.PATH_NAME)
  //   if (moduleName || pathName) {
  //     setMenuInfo({
  //       moduleName: moduleName || menuInfo.moduleName,
  //       pathName: pathName || menuInfo.pathName
  //     })
  //   }
  // }

  // useEffect(() => {
  //   getSessionMenuInfo()
  // }, [pathname])

  useEffect(() => {
    let userInfoJson: any = window.localStorage.getItem(RIMS_LOCAL_STG_CONSTANTS.USER_INFO)
    let userInfo: any = typeof userInfoJson === 'string' ? JSON.parse(userInfoJson) : null
    setUsername(userInfo?.username);
    setAuthMechanism(userInfo?.authMechanism);
    setRoleTypeList(userInfo?.roleTypeList);


    // default remove menu-collapsed
    var totalbody = $("body");
    if (totalbody.hasClass("menu-collapsed")) {
      totalbody.removeClass("menu-collapsed");
      eventBus.emit(RTQ_EVENTS.HEADER.TOGGLE_MENU_EXTEND)
    }

    $(".menu-toggle").click(function () {
      var totalbody = $("body");
      if (totalbody.hasClass("menu-collapsed")) {
        totalbody.removeClass("menu-collapsed");
        eventBus.emit(RTQ_EVENTS.HEADER.TOGGLE_MENU_EXTEND)
      }
      else {
        totalbody.addClass("menu-collapsed");
        eventBus.emit(RTQ_EVENTS.HEADER.TOGGLE_MENU_COLLAPSED)
      }
    });

    $(".menu-fade").click(function () {
      alert();
      var menubtn = $(".user-dropdown");
      if (menubtn.hasClass("open")) {
        menubtn.removeClass("open");
      } else {
        menubtn.addClass("open");
      }
    });
    // User Menu 
    //  $(".user-dropdown").click(function() {
    //   var menubtn = $(".user-dropdown");
    //   if (menubtn.hasClass("open")) {
    //       menubtn.removeClass("open");
    //   } else {
    //       menubtn.addClass("open");
    //   }
    // });

    $("html").click((e) => {
      var menubtn = $(".user-dropdown");
      if (e.target.id === 'layout-user-dropdown-target') {
        if (menubtn.hasClass("open")) {
          menubtn.removeClass("open");
        } else {
          menubtn.addClass("open");
        }
      } else {
        const belongUserInfo = findParentNode(e.target)
        if (belongUserInfo) {
          if (menubtn.hasClass("open")) {
            menubtn.removeClass("open");
          } else {
            menubtn.addClass("open");
          }
        } else {
          if (menubtn.hasClass("open")) {
            menubtn.removeClass("open");
          }
        }
      }
    });
    // judgeUserHasESign()


    eventBus.addListener(RTQ_EVENTS.COMMON.EXPORT_FILE_START, exportFileStart)
    eventBus.addListener(RTQ_EVENTS.COMMON.EXPORT_FILE_END, exportFileEnd)

    eventBus.addListener(RTQ_EVENTS.ADMIN.DOWNLOAD_TEMPLATE_START, downloadTemStart)
    eventBus.addListener(RTQ_EVENTS.ADMIN.DOWNLOAD_TEMPLATE_END, downloadTemEnd)
    return () => {
      eventBus.removeListener(RTQ_EVENTS.COMMON.EXPORT_FILE_START, exportFileStart)
      eventBus.removeListener(RTQ_EVENTS.COMMON.EXPORT_FILE_END, exportFileEnd)

      eventBus.removeListener(RTQ_EVENTS.ADMIN.DOWNLOAD_TEMPLATE_START, downloadTemStart)
      eventBus.removeListener(RTQ_EVENTS.ADMIN.DOWNLOAD_TEMPLATE_END, downloadTemEnd)
    }
  }, []);

  const exportFileStart = (func: any) => {
    if (func.api === EXPORT_AUDIT_LOG_URL) {
      setLoadingWord('We are currently exporting your data. Please check your downloads once its completed. Please see, only the first 10,000 records can be exported.')
    } else {
      setLoadingWord('We are currently exporting your data. Please check your downloads once its completed')
    }
    setLoading(true);
    if (func.api) {
      readyToPolling(func.api, func.data, func.enqueueSnackbar)
    } else {
      func();
    }
  }

  const exportFileEnd = (func: any) => {
    setLoading(false);
    setLoadingWord('')
  }

  const downloadTemStart = (func: any) => {
    setLoadingWord('We are currently exporting your data. Please check your downloads once its completed')
    setLoading(true);
    if (func.api) {
      let timeZone = -new Date().getTimezoneOffset() / 60
      if (func.api.indexOf(`${UAM_PREFIX}`) > 0 || func.api.indexOf(`${SYSLIB_PREFIX}`) > 0) {
        downloadFileCommon(func.api, func.data, func.enqueueSnackbar, 'download template')
      } else {
        const downloadData = {
          ...func.data,
          "timeZone": timeZone
        }
        downloadFileCommon(func.api, downloadData, func.enqueueSnackbar, 'download template')
      }
    } else {
      func();
    }
  }

  const downloadTemEnd = (func: any) => {
    setLoading(false);
  }

  const readyToPolling = (api: string, data: any, enqueueSnackbar: any) => {
    let timeZone = -new Date().getTimezoneOffset() / 60
    // if (api.indexOf(`${UAM_PREFIX}`) > -1 || api.indexOf(`${SYSLIB_PREFIX}`) > -1) {
    //   downloadFileCommon(`${api}&timeZone=${timeZone}`, {}, enqueueSnackbar)
    // } else {
    const downloadData = {
      ...data,
      "timeZone": timeZone
    }
    downloadFileCommon(api, downloadData, enqueueSnackbar)
    // }
  }

  const downloadFileCommon = (url: string, data = {}, enqueueSnackbar: any, type = '') => {
    const dataLength = Object.keys(data).length
    let token = window.localStorage.getItem(RIMS_LOCAL_STG_CONSTANTS.LOGIN_TOKEN)
    // let token = 'eyJraWQiOiIzelV4eHVkNEx4NElEakFhdmhhcUlBQzJIR0pEaHRoajZvM1IzVUs3NlQ0PSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIzZTE4OWY3Mi1hMzlhLTQ2NjItODNiYS0zNmQ3OTBkZDMwMzMiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9rM0E0UkZyVGUiLCJjbGllbnRfaWQiOiI3NmowMDdtOG02MnZ2ZXQ2amVjODNkMnVkIiwib3JpZ2luX2p0aSI6ImU2N2MwYmQ5LTUyMmMtNDUwNC04MzczLWJhN2QwYmVkOWFmNCIsImV2ZW50X2lkIjoiOTU5MmZjM2EtZGFmNy00MGUxLWIwYWMtMTYyMjhjNTc0N2EzIiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTY5MzM2NzQzOSwiZXhwIjoxNjkzNDUzODM5LCJpYXQiOjE2OTMzNjc0MzksImp0aSI6IjI3MmZmM2E5LWE3MWMtNDA2ZS1hMzE3LWE1Y2VmZGVmYTczMiIsInVzZXJuYW1lIjoiYWRtaW4ifQ.Akt86L9cQdhIyfel3y5TtEqpdb9AXsChu0WmGRd3aSxuEkpxkoWPd6ujr_CRd_kf5piipFbdzxbj2UNTIX67uTMF07PVx1pS3scoOIifBb2TD8xvybWDwnK8ycIvR77Vd_kpeoYlOQKJ-I069UapzX7V4wNzRC_pcI2txKcsxPHVedcEPSQ5G4-ustpmqnr4NQggMD3Owmshxz5bBHgqKL6r7f6h9sm_Gjl65bCeX7BgpLP8kQw2rd_rRTcPeTF4FbBFYgZeMf8kiMyg2XLdqoPa51rKhdwsavbMICw4vcjLkTB-bSv80rsT3yL5BJo8KOkDE0_k7X0OuGvBtXO7Ng'
    let executePolling = setTimeout(
      () => {
        service({
          method: dataLength ? 'post' : 'get',
          url: url,
          responseType: 'arraybuffer',
          data: data
        }).then((res: any) => {
          if (res.headers['export-status'] === '3' || type === 'download template') {
            eventBus.emit(RTQ_EVENTS.COMMON.EXPORT_FILE_END)
            eventBus.emit(RTQ_EVENTS.ADMIN.DOWNLOAD_TEMPLATE_END) // download template file
            clearTimeout(executePolling);
            let fileName = res.headers['content-disposition'].split(';')[1].split('filename=')[1];
            if (!res.data) { return }
            let url = window.URL.createObjectURL(new Blob([res.data]));
            enqueueSnackbar?.("File downloaded successfully.", { variant: "success", anchorOrigin: { vertical: 'top', horizontal: 'center' } });
            let element = document.createElement('a');
            element.style.display = 'none';
            element.href = url;
            element.setAttribute('download', decodeURIComponent(fileName));
            document.body.appendChild(element);
            element.click();
          } else {
            downloadFileCommon(url, data, enqueueSnackbar);
          }
        }).catch((err: any) => {
          clearTimeout(executePolling);
          eventBus.emit(RTQ_EVENTS.COMMON.EXPORT_FILE_END)
          eventBus.emit(RTQ_EVENTS.ADMIN.DOWNLOAD_TEMPLATE_END) // download template file
          if (err.response.status === 401) {
            window.location.href = '/';
            logoutFunction()
          }
          if (enqueueSnackbar) {
            const unit8Msg: any = new Uint8Array(err.response.data)
            const error_msg = JSON.parse(String.fromCharCode.apply(null, unit8Msg))
            enqueueSnackbar(error_msg.message, { variant: "error", anchorOrigin: { vertical: 'top', horizontal: 'center' } });
            // enqueueSnackbar('Failed to download the PDF, Please try again later', { variant: "error", anchorOrigin: { vertical: 'top', horizontal: 'center' } });
          }
        })
      }, 2000)

  }

  const [loading, setLoading] = useState(false);
  const [loadingWord, setLoadingWord] = useState('');

  const [esignTitle, setEsignTitle] = useState('Set E-Signature Password')
  const [haveESignpsw, setHaveESignpsw] = useState(false)
  const [openEsignDrawer, setOpenEsignDrawer] = useState(false);

  const [changePswVisible, setChangePswVisible] = useState(false);
  const [isSetESign, setIsSetESign] = useState(true);

  const linkTo = useNavigate();
  const [menuInfo, setMenuInfo] = useState({
    moduleName: 'Dashboard',
    pathName: 'Dashboard'
  })

  const findParentNode = (e: any): any => {
    const parentElement = e.parentElement || e.parentNode
    if (parentElement) {
      if (parentElement.id === 'layout-user-dropdown-target') {
        return true
      } else {
        return findParentNode(parentElement)
      }
    } else {
      return false
    }
  }

  const handleClickMenuItem = (pathName: string, disclickabled: boolean | undefined) => {
    // setMenuInfo({
    //   ...menuInfo,
    //   moduleName,
    //   pathName
    // })
    // window.sessionStorage.setItem(RIMS_SESSION_STG_CONSTANTS.MODULE_NAME, moduleName)
    // window.sessionStorage.setItem(RIMS_SESSION_STG_CONSTANTS.PATH_NAME, pathName)
    // if (!disclickabled) {
    //   linkTo(`${pathName}`);
    // }
    // close menu
    //setAnchorEl(null);


    // if (localStorage.getItem(RIMS_LOCAL_STG_CONSTANTS.DISABLE_BACK_PREVIOUS) == 'true') {
    //   setOpenDialog(true);
    //   setCurrentClickedPath(pathName);
    // } else {
    if (!disclickabled) {
      linkTo(`${pathName}`);
    }
    // }

  }

  const handleClickLogout = (e: any) => {
    // setLoading(true)
    // logout().then((data: any) => {
    // }).catch((err) => {
    //   console.log(err);
    // }).finally(() => {
    //   logoutFunction();

    cookie.remove(RIMS_LOCAL_STG_CONSTANTS.LOGIN_TOKEN, COOKIE_SETUP)
    window.location.href = `${process.env.REACT_APP_REDIRECT_URL}?redirect=${window.location.origin}`;
    //   setLoading(false);
    // })
  }

  const judgeUserHasESign = () => {
    judgeUserHasSignaturePassword().then((res: any) => {
      if (res.code === 200) {
        setHaveESignpsw(res.data)
        if (res.data) {
          setEsignTitle('Change E-Signature Password')
        } else {
          setEsignTitle('Set E-Signature Password')
        }
      }
    }).catch((err: any) => {
      enqueueSnackbar(firstWordCase(err.response.data.message), { variant: "error", anchorOrigin: { vertical: 'top', horizontal: 'center' } });
    }).finally(() => {
    })
  }
  const openESignature = () => {
    setEsignTitle(haveESignpsw ? 'Change E-Signature Password' : 'Set E-Signature Password')
    setOpenEsignDrawer(true);
  }

  const closeEsignPasswordDrawer = () => {
    setOpenEsignDrawer(false);
  }

  const handleClickChangePsw = () => {
    setChangePswVisible(true)
  }

  const saveChangePwd = () => {
    setChangePswVisible(false)
  }

  const expandMenuTrigger = (e: any) => {
    var submenu = $(e.target).parent(".has-submenu");
    if (submenu.hasClass("open")) {
      submenu.removeClass("open");
    }
    else {
      submenu.addClass("open");
      submenu.siblings().removeClass("open");
    }
  }

  const getOpenMenuList = (data: Menus[], path: string): any => {
    for (let i = 0; i < data.length; i++) {
      if (data[i] && data[i].path == path) {
        return [data[i]];
      }
      if (data[i] && data[i].children) {
        let d = getOpenMenuList(data[i].children || [], path);
        if (d) return d.concat(data[i]);
      }
    }
  };

  useEffect(() => {
    const menus = getMenuList(authList, roleTypeList, state);
    setMenuList(menus);
  }, [roleTypeList, pathname]);

  useEffect(() => {
    const openMenus = getOpenMenuList(menuList, pathname)?.reverse() || [];
    setOpenMenuList(openMenus);
  }, [pathname, menuList]);

  const openMenuIDList = useMemo(() => {
    // console.log(openMenuList?.map((item: Menus) => item.menuID));
    return openMenuList?.map((item: Menus) => item.menuID);
  }, [openMenuList]);

  const getMenuClass = (menu: Menus) => {
    const className = [];
    if (
      menu.children?.length &&
      menu.children.some((item: Menus) => item.visible)
    ) {
      className.push("has-submenu");
    }
    if (menu.menuID == openMenuIDList[0]) {
      className.push("active");
    }
    return className;
  };

  const getLev1MenuItem = (lev1Menu: Menus) => {
    const className = getMenuClass(lev1Menu).join(" ");
    const hasSubmenu = className.includes("has-submenu");
    return (
      <>
        <li className={className} key={lev1Menu.menuID}>
          <a
            href="javascript:void(0)"
            id="reports"
            onClick={() => lev1Menu.path && handleClickMenuItem(lev1Menu.path, lev1Menu.disclickabled)}
          >
            <i className={lev1Menu.iconfont}></i>{" "}
            <span> {lev1Menu.caption} </span>
          </a>{" "}
          {hasSubmenu && (
            <i className="icon-down-open" onClick={expandMenuTrigger}></i>
          )}
          {hasSubmenu && (
            <div className="divsubmenu">
              <ul className="submenu animated faster slideInDown">
                {lev1Menu.children
                  ?.filter((item: Menus) => item.visible)
                  .map((lev2: Menus) => getLev2MenuItem(lev2))}
              </ul>
            </div>
          )}
        </li>
      </>
    );
  };

  const getLev2MenuItem = (lev2Menu: Menus) => (
    <MenuItem
      className={lev2Menu.menuID == openMenuIDList[1] ? "active" : ""}
      onClick={() => handleClickMenuItem(lev2Menu.path, lev2Menu.disclickabled)}
    >
      <a>{lev2Menu.caption}</a>
    </MenuItem>
  );

  // const handleClickYesBtn = () => {
  //   localStorage.setItem(RIMS_LOCAL_STG_CONSTANTS.DISABLE_BACK_PREVIOUS, 'false');
  //   setOpenDialog(false)
  //   linkTo(currentClickedPath);
  // }

  return (
    <div className='nav-container'>
      <nav className='menu-nav'>
        <div className="logo-space">
          {/* <h4>Admin Center</h4> */}
        </div>
        <ul className="main-menu" id="loadpage">
          {menuList.filter((item: Menus) => item.visible).map((lev1) =>
            getLev1MenuItem(lev1)
          )}
        </ul>
        {/* <a href="javascript:void(0)" className="menu-toggle"><i className="icon-chevron-left-1"></i> <span></span></a> */}
      </nav>
      <header>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="logo-space">
          {/* <img src={require(`../assets/images/logo.png`)}></img> */}
          <h4 style={{ display: 'flex', alignItems: 'center' }}>
            {/* <IconButton aria-label="delete"> */}
            {/* <DragIndicatorIcon /> */}
            {/* <i className="icon icon-dots-menu1"></i> */}
            {/* </IconButton> */}

            <img src={require(`../assets/images/logo.png`)}></img>
            <span>Admin Center</span>

            {/* <IconButton aria-label="delete" sx={{ ml: 6 }}>
              <i className="icon icon-menu6"></i>
              <MenuIcon />
            </IconButton> */}
          </h4>

          <div>
            {/* style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }} */}
            {/* <div style={{ marginLeft: '20px', flex: 1, display: 'flex', alignItems: 'center' }}>
              <BreadCrumb openMenuList={openMenuList}></BreadCrumb>
            </div> */}
            <div className="user-dropdown text-cap">
              <div style={{ display: 'flex', alignItems: 'center', height: '40px' }} id="layout-user-dropdown">
                {/* <IconButton aria-label="delete" className='header-icon'>
                  <i className="icon icon-search21"></i>
                </IconButton>
                <IconButton aria-label="delete" className='header-icon'>
                  <i className="icon icon-round-11"></i>
                </IconButton>
                <IconButton aria-label="delete" className='header-icon'>
                  <i className="icon icon-notification1"></i>
                </IconButton> */}
                <IconButton aria-label="delete" id="layout-user-dropdown-target" className='header-icon'>
                  <i className="icon icon-user1"></i>
                </IconButton>
                {/* <em style={{ marginTop: 0 }} className='icon-user1 circle'></em> */}
                {/* <span style={{ maxWidth: '180px' }} className="text-ellipsis"><b>Hi,</b> &nbsp;{username}</span>
                <i style={{ marginTop: 0 }} className="icon-down-open arrow-down" ></i> */}
              </div>
              <ul>
                {/* <li><i className="icon-user2"></i>My Profile</li> */}
                {/* {authMechanism != 'customer sso' && isSetESign && <MenuItem key={esignTitle} onClick={openESignature}><i className="icon-vpn_key"></i>{haveESignpsw ? 'Change E-Signature Password' : 'Set E-Signature Password'}</MenuItem>} */}

                {/* <MenuItem key='testResource2' onClick={handleClickResource2}><i className="icon-power-1"></i> Resource2</MenuItem> */}
                {(authMechanism != 'customer sso') && <MenuItem key='changePassword' onClick={handleClickChangePsw}><i className="icon-lock"></i> Change Password</MenuItem>}
                <MenuItem key='Logout' onClick={handleClickLogout}><i className="icon-power-1"></i> Logout</MenuItem>
              </ul>
            </div>
          </div>
        </div >
        <ChangePswDialog
          visible={changePswVisible}
          cancel={() => { setChangePswVisible(false) }}
          submit={saveChangePwd}
          username={username}
          disabledSubmit={false}
          open={() => { }}>
        </ChangePswDialog>
        {/* <CommonDialog openDialog={openDialog} title={'Unsaved Changes'} content={'Are you sure you want to leave this page? Any unsaved changes will be lost.'} isDeleteDialog={false} handlePrimaryBtn={() => handleClickYesBtn()} handleSecondaryBtn={() => setOpenDialog(false)} primaryBtnName={'Yes'} secondaryBtnName={'No'}>
        </CommonDialog> */}
      </header >
      <Loading open={loading} wording={loadingWord} />
    </div >
  );
}
export default memo(withAuth(AppHeader));
