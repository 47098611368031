import { RIMS_LOCAL_STG_CONSTANTS, RTQ_EVENTS } from "../constants";
import eventBus from "./eventBus";

export function translateStatus(status: any) {
  if (status === '') {
    return ''
  } else {
    switch (Number(status)) {
      case 0:
        return 'Draft';
      case 1:
        return 'Active';
      case 2:
        return 'Inactive'
      case 3:
        return 'Archived'
      default:
        return 'Draft'
    }
  }
}

export function mappingAdminType(type: string) {
  switch (type) {
    case 'user':
      return 0;
    case 'role':
      return 1;
    case 'group':
      return 2;
    case 'acl':
      return 3;
    default:
      return 0
  }
}

export function uniqueArray(arr: any[], propName?: string) {
  const res = new Map();
  if (propName) {
    return arr.filter((a) => !res.has(a[propName]) && res.set(a[propName], 1));
  } else {
    return arr.filter((a) => !res.has(a) && res.set(a, 1));
  }
}

export function titleCase(str: string) {
  let newStr = str.split(" ");
  for (let i = 0; i < newStr.length; i++) {
    newStr[i] = newStr[i].slice(0, 1).toUpperCase() + newStr[i].slice(1).toLowerCase();
  }
  return newStr.join(" ");
}

export function firstWordCase(parameter: string) {
  return parameter.slice(0, 1).toUpperCase() + parameter.slice(1).toLowerCase();
}

export function handleExportByType(api: string, enqueueSnackbar: any, data?: any) {
  let params = {
    api: api,
    data: data ? data : {},
    enqueueSnackbar: enqueueSnackbar
  }
  eventBus.emit(RTQ_EVENTS.COMMON.EXPORT_FILE_START, params)
}

export function logoutFunction() {
  localStorage.removeItem(RIMS_LOCAL_STG_CONSTANTS.LOGIN_TOKEN);
  localStorage.removeItem(RIMS_LOCAL_STG_CONSTANTS.USER_INFO);
}

const isObject = (target: any) => {
  return (typeof target === 'object' || typeof target === 'function')
    && (target !== null);
};

export function deepClone(target: any, map = new WeakMap()) {
  if (map.get(target)) {
    return target;
  }
  if (isObject(target)) {
    map.set(target, true);
    const cloneTarget: any = Array.isArray(target) ? [] : {};
    for (let prop in target) {
      if (target.hasOwnProperty(prop)) {
        cloneTarget[prop] = deepClone(target[prop], map);
      }
    }
    return cloneTarget;
  } else {
    return target;
  }
};

export function getObjectDataType(data: any) {
  const temp = Object.prototype.toString.call(data);
  const type = temp?.match(/\b\w+\b/g) || [];
  return (type.length < 2) ? 'Undefined' : type[1];
}

export function isObjectChanged(source: any, comparison: any): any {
  const iterable = (data: any) => ['Object', 'Array'].includes(getObjectDataType(data));
  if (getObjectDataType(source) !== getObjectDataType(comparison)) {
    return true;
  }
  const sourceKeys = Object.keys(source);
  const comparisonKeys = Object.keys({ ...source, ...comparison });
  if (sourceKeys.length !== comparisonKeys.length) {
    return true;
  }
  return comparisonKeys.some((key: any) => {
    if (iterable(source[key])) {
      return isObjectChanged(source[key], comparison[key]);
    } else {
      return source[key] !== comparison[key];
    }
  });
}

export const uploadFileSize = (input: any) => {
  let Sys = {
    ie: false,
    firefox: false,
    Chrome: false
  };
  let filesize = 0;
  const userAgent = navigator.userAgent;

  if (userAgent.indexOf("MSIE") > 0 || userAgent.indexOf("rv:11.0") > 0) {
    Sys.ie = true;
  }
  if (userAgent.indexOf("Firefox") > 0) {
    Sys.firefox = true;
  }
  if (userAgent.indexOf("Chrome") > 0) {
    Sys.Chrome = true;
  }

  if (Sys.firefox || Sys.Chrome) {
    filesize = input.files[0].size;
  } else if (Sys.ie) {
    //@ts-ignore
    const fileobject = new ActiveXObject("Scripting.FileSystemObject");
    const file = fileobject.GetFile(input.value);
    filesize = file.Size;
  }
  return filesize / (1024 * 1024)
}
export function dateSortComparator(a: any, b: any): any {
  return Date.parse(a ?? 0) - Date.parse(b ?? 0)
}