import { RIMS_LOCAL_STG_CONSTANTS } from "../constants";

export function withAuth(Component: any) {
  function ComponentWithAuthProp(props: any) {
    const authListJson: any = window.localStorage.getItem(RIMS_LOCAL_STG_CONSTANTS.MENU_AUTH_LIST);
    const authList: any = typeof authListJson === 'string' ? JSON.parse(authListJson) : [];
    const dataAuthListJson: any = window.localStorage.getItem(RIMS_LOCAL_STG_CONSTANTS.DATA_AUTH_LIST);
    const dataAuthList: any = typeof dataAuthListJson === 'string' ? JSON.parse(dataAuthListJson) : [];

    return (
      <Component
        {...props}
        authList={authList}
        dataAuthList={dataAuthList}
      />
    );
  }

  return ComponentWithAuthProp;
}