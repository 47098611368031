import service from './index'

const prefix = '/common-auth';

export const login = (userInfo: any) => service.post(prefix + '/login', userInfo);

export const logout = () => service.delete(prefix + '/logout');

export const forgotPassword = (userName: any) => service.get(`${prefix}/forgot-password?username=${userName}`);

export const forgotPasswordConfirm = (useInfo: any) => service.post(prefix + '/setup-password', useInfo);

export const updatePassword = (userInfo: any) => service.put(prefix + '/password', userInfo);

export const authValidateLink = (token: string, username: string) => service.get(`${prefix}/validate-link?token=${token}&username=${username}`);

export const ADLogin = () => service.get(`${prefix}/ad-login-url`);

export const getAuthInfoByCode = (code: string) => service.get(`${prefix}/oauth2/auth-info?code=${code}`);

export const associateSoftwareToken = () => service.post(`${prefix}/mfa/software-token/association`);

export const verifySoftwareToken = (code: string) => service.post(`${prefix}/mfa/software-token/verification?mfa-code=${code}`);

export const getAuthInfoByMFA = (body: any) => service.post(`${prefix}/mfa/auth-info`, body);

export const validUsername = (params: any) => service.get(`${prefix}/login-info`, {params});

export const getAddLoginUrl = () => service.get(`${prefix}/ad-login-url`);

export const activateUser = (params: any) => service.get(`${prefix}/activation`, {params});

export const getPasswordComplexityPolicy = () => service.get(`${prefix}/password-complexity-policy`);

export const AUTH_PREFIX = prefix;